import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { AppContainer } from 'components/ui/appContainer'
import { NextLink } from 'components/wrapper/nextLink'
import { NextSeo } from 'next-seo'
import { FC } from 'react'
import { Routes } from 'routes'

const NotFoundPage: FC = () => {
  return (
    <>
      <NextSeo title="404 | HoteCo" noindex={true} />
      <AppContainer>
        <Box py={20} textAlign="center">
          <Heading as="h1" fontSize="112" fontWeight="medium" color="#0069B2">
            404
          </Heading>
          <Text fontSize="24" fontWeight="bold" mt={10}>
            お探しのページは見つかりませんでした。
          </Text>
          <Text fontSize="14" lineHeight="24px" mt={4}>
            アクセスしようとしたページは現在利用できない可能性があります。
            <br />
            お手数ですが、下記のリンクよりお戻りください。
          </Text>

          <Button as={NextLink} href={Routes.searchHotelsTop()} mt={16}>
            トップページへ戻る
          </Button>
        </Box>
      </AppContainer>
    </>
  )
}

export default NotFoundPage
